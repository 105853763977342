import React from "react";
import Link from "./Link";

const NavItems = () => {
  return (
    <div className="nav-items">
      {/* <Link href="/" className="header-link-item">
        品牌介紹
      </Link> */}

      <Link href="/alacarte" className="header-link-item">
        單點菜單
      </Link>

      <Link href="/room-menu" className="header-link-item">
        桌菜菜單
      </Link>
      {/* <Link href="/" className="header-link-item">
        用餐空間
      </Link>
      <Link href="/room-menu" className="header-link-item">
        餐廳資訊
      </Link> */}
      <a
        href="https://inline.app/booking/erito/erito"
        className="header-link-item"
        id="booking"
      >
        立即訂位
      </a>
      {/* <Link href="/album" className="link-item">
相片集 Album
</Link> */}
      {/* <Link href="/about" className="link-item">
關於我們 About Us
</Link>
<Link href="/contact" className="link-item">
聯絡我們 Contact Us
</Link> */}
      {/* 
<Link href="/events" className="link-item">
優惠活動 Events
</Link> */}
      {/* <Link href="/careers" className="link-item">
徵求夥伴 Careers
</Link> */}
      {/* <Link href="/reservations" className="header-link-item">
    訂位
  </Link> */}
    </div>
  );
};

export default NavItems;
